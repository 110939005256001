import React from "react"
import { Link } from "gatsby"

const PersonLink = ({ person }) => {
  return (
    <div>
      <Link to={person.fields.slug}>{person.frontmatter.name}</Link>
    </div>
  )
}

export default PersonLink
