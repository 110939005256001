import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Content, { HTMLContent } from "../components/Content"
import PersonLink from "../components/PersonLink"

export const CasesTemplate = ({ content, contentComponent }) => {
  const CasesContent = contentComponent || Content

  return (
    <section className="section">
      {""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <CasesContent className="markdown" content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

const CasesPage = ({ data }) => {
  const { casesQuery, peopleQuery} = data
  const cases = casesQuery["frontmatter"]
  const people = peopleQuery["edges"]
  

  const imageStyle = { borderRadius: "0px", maxWidth: 300 }

  return (
    <Layout>
      <h1 className="text-3xl my-6 font-bold">{cases.name}</h1>

      <CasesTemplate
        content={casesQuery.html}
        contentComponent={HTMLContent}
      />

      <Img style={imageStyle} fluid={cases.image.childImageSharp.fluid} />
      <div className="font-bold mt-4">People</div>
      {people &&
        people.map((person, i) => <PersonLink key={i} person={person.node} />)}
      <div className="font-bold mt-4">Partners</div>
    </Layout>
  )
}

export default CasesPage

// String! implies non-nullable
// We are guaranteed an id because once was assigned on page creation
export const pageQuery = graphql`
  query CasesByID($id: String!, $people: [String]) {
    casesQuery: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        people
        image {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    peopleQuery: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "person-page" }
          name: { in: $people }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
          }
          fields {
            slug
          }
        }
      }
    }

  }
`